export var SelectorType;
(function (SelectorType) {
  SelectorType["ENV"] = "env";
  SelectorType["CONFIG"] = "shared config entry";
})(SelectorType || (SelectorType = {}));
export const stringUnionSelector = (obj, key, union, type) => {
  if (!(key in obj)) return undefined;
  const value = obj[key].toUpperCase();
  if (!Object.values(union).includes(value)) {
    throw new TypeError(`Cannot load ${type} '${key}'. Expected one of ${Object.values(union)}, got '${obj[key]}'.`);
  }
  return value;
};