export const RequestChecksumCalculation = {
  WHEN_SUPPORTED: "WHEN_SUPPORTED",
  WHEN_REQUIRED: "WHEN_REQUIRED"
};
export const DEFAULT_REQUEST_CHECKSUM_CALCULATION = RequestChecksumCalculation.WHEN_SUPPORTED;
export const ResponseChecksumValidation = {
  WHEN_SUPPORTED: "WHEN_SUPPORTED",
  WHEN_REQUIRED: "WHEN_REQUIRED"
};
export const DEFAULT_RESPONSE_CHECKSUM_VALIDATION = RequestChecksumCalculation.WHEN_SUPPORTED;
export var ChecksumAlgorithm;
(function (ChecksumAlgorithm) {
  ChecksumAlgorithm["MD5"] = "MD5";
  ChecksumAlgorithm["CRC32"] = "CRC32";
  ChecksumAlgorithm["CRC32C"] = "CRC32C";
  ChecksumAlgorithm["CRC64NVME"] = "CRC64NVME";
  ChecksumAlgorithm["SHA1"] = "SHA1";
  ChecksumAlgorithm["SHA256"] = "SHA256";
})(ChecksumAlgorithm || (ChecksumAlgorithm = {}));
export var ChecksumLocation;
(function (ChecksumLocation) {
  ChecksumLocation["HEADER"] = "header";
  ChecksumLocation["TRAILER"] = "trailer";
})(ChecksumLocation || (ChecksumLocation = {}));
export const DEFAULT_CHECKSUM_ALGORITHM = ChecksumAlgorithm.CRC32;